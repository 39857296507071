<template>
  <v-dialog v-model="signatureDialogComp" fullscreen persistent>
    <v-card>
      <v-card-title class="text-h4">Verificación de datos y aceptación</v-card-title>
      <v-card-text>
        <terms-form
          v-if="showTermsForm && !termsFormCompleted"
          :semicyuc="semicyuc"
          :form-data="formData"
          :student-id="signerData.studentInfo && signerData.studentInfo.id"
          :assistance-id="signerData.studentInfo && signerData.studentInfo.assistanceId"
          @formCompleted="completeTermsForm"
        />

        <biometric-form
          v-else-if="showBiometricForm && termsFormCompleted && !biometricFormCompleted"
          :student-vidsigner-code="signerData.vidsignerCodeIdentifier"
          :assistance-id="signerData.studentInfo && signerData.studentInfo.assistanceId"
          @formCompleted="completeBiometricForm"
        />

        <terminate-btn v-else @terminateProcess="closeDialog"></terminate-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    termsForm: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session-signatures/SignatureDialogTermsForm"
      );
    },
    biometricForm: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session-signatures/SignatureDialogBiometricForm"
      );
    },
    terminateBtn: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session-signatures/SignatureDialogTerminateBtn"
      );
    },
  },
  props: {
    signatureDialogVisible: { type: Boolean, default: false },
    signerData: { type: Object, required: true },
    semicyuc: { type: Boolean, default: false },
  },
  data: () => ({
    formData: {
      user: {
        name: null,
        surname: null,
        email: null,
        gender: null,
        birthDate: null,
      },
      rgpdAuthorize: null,
      semicyucAuthorize: null,
      signature: null,
    },
    showTermsForm: null,
    termsFormCompleted: null,
    sendingTermsForm: null,
    showBiometricForm: null,
    biometricFormCompleted: null,
    isTrainerSignature: null,
  }),
  beforeMount() {
    this.isTrainerSignature = this.signerData.isTrainer;
  },
  mounted() {
    this.showTermsForm = !!this.signerData.pendingDocuments.find(doc => !doc.isBiometric);
    this.showBiometricForm = !!this.signerData.pendingDocuments.find(doc => doc.isBiometric);

    if (!this.showTermsForm) this.completeTermsForm();
    if (!this.showBiometricForm) this.completeBiometricForm();

    this.resetFormData();
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    completeTermsForm() {
      this.termsFormCompleted = true;
    },
    completeBiometricForm() {
      this.biometricFormCompleted = true;
    },
    resetFormData() {
      const signerData = this.signerData.studentInfo;

      if (signerData) {
        this.formData.user.name = signerData.name;
        this.formData.user.gender = signerData.gender;
        this.formData.user.surname = signerData.surname;
        this.formData.user.email = signerData.email;
        this.formData.user.birthDate = signerData.birthDate;
      }
    },
  },
  computed: {
    signatureDialogComp() {
      return this.signatureDialogVisible;
    },
  },
};
</script>

<style scoped></style>
